<template>
  <div>

    <app-step v-if="!show_printview" step="4" />

    <div v-if="!show_document" class="container pt-5 mt-5 text-center">
      
      <span v-if="show_status == 1" class="text-success">
        <i class="fad fa-badge-check display-1"></i>
        <h4 class="my-3">¡Compra realizada con éxito!</h4>
      </span>

      <span v-if="show_status == 2" class="text-danger">
        <i class="fad fa-exclamation-triangle display-1"></i>
        <h4 class="my-3">Hubo un problema al procesar la compra</h4>
      </span>

      <span v-if="show_status == 3" class="text-info">
        <i class="fad fa-badge-check display-1"></i>
        <h4 class="my-3">Orden completada con éxito.</h4>
        <h6 class="my-3">Realizar el pago para confirmar la compra.</h6>
      </span>

      <b-button v-on:click="show_document = true"
        :variant="(show_status == 1) ? 'outline-success' : (show_status == 2) ? 'outline-danger' : 'outline-info'" 
        class="mt-3 mx-2">
        <i class="fad fa-file-alt"></i>
        <span class="pl-2">Ver Detalles</span>
      </b-button>
      <b-button  v-on:click="print()"
        :variant="(show_status == 1) ? 'outline-success' : (show_status == 2) ? 'outline-danger' : 'outline-info'" 
        class="mt-3 mx-2">
        <i class="fad fa-print"></i>
        <span class="pl-2">Imprimir recibo</span>
      </b-button>

    </div>

    <div v-if="show_document" class="container pt-4">
      <div class="row px-3 d-flex justify-content-between">
        <div class="col-sm-4 border border-bottom-0">
          <div class="row d-flex justify-content-center">

            <div class="col-2 my-auto py-2">
              <h4 v-if="show_status == 1" class="my-0 text-success"><i class="fad fa-badge-check"></i></h4>
              <h4 v-if="show_status == 2" class="my-0 text-danger"><i class="fad fa-exclamation-triangle"></i></h4>
              <h4 v-if="show_status == 3" class="my-0 text-info"><i class="fad fa-badge-check"></i></h4>
            </div>

            <div class="col-8 my-auto py-2">
              <h5 v-if="show_status == 1" class="my-0 text-success">Compra exitosa!</h5>
              <h5 v-if="show_status == 2" class="my-0 text-danger">Proceso fallido!</h5>
              <h5 v-if="show_status == 3" class="my-0 text-info">Orden completa!</h5>
            </div>

          </div>
        </div>
        <div class="col-sm-1 px-0 my-auto text-right">
          <b-button v-on:click="print()"
            v-if="!show_printview" 
            variant="white">
            <h4 v-if="show_status == 1" class="mb-0 text-success"><i class="fad fa-print"></i></h4>
            <h4 v-if="show_status == 2" class="mb-0 text-danger"><i class="fad fa-print"></i></h4>
            <h4 v-if="show_status == 3" class="mb-0 text-info"><i class="fad fa-print"></i></h4>
          </b-button>
        </div>
      </div>
    </div>

    <div v-if="show_document" class="container pb-5">
      
      <hr class="mt-0">

      <div class="row px-3">
        <div class="col-sm-8 text-muted small">
          <p class="mb-0"><strong>Datos de orden</strong></p>
          <p class="mb-0">Folio: {{ order_final.order.order_id }}</p>
          <p class="mb-0">{{ date }}</p>
        </div>
        <div class="col-sm-4 text-muted small text-right">
          <p class="mb-0"><strong>Usuario</strong></p>
          <p class="mb-0">{{ customer.firstname }} {{ customer.lastname }}</p>
          <p class="mb-0">{{ customer.email }}</p>
        </div>
      </div>

      <div class="row px-3"><hr class="col-12"></div>

      <div class="row d-flex justify-content-between px-3">
        
        <div class="col-sm-4 text-muted">
          
          <h5 class="text-muted">Datos de Entrega</h5>
          <p class="mb-0"><strong>{{ shipping.method }}</strong></p>

          <div v-if="shipping.key == 2" class="">
            <p class="mb-0">Hora seleccionada: {{ shipping.data.hour }}:00 p.m.</p>
            <p v-if="shipping.data.comentaries !== ''" class="mb-0">Comentarios</p>
            <p>{{ shipping.data.comentaries }}</p>
          </div>

          <div v-if="shipping.key == 3" class="">
            <p class="mb-0">Nombre: {{ shipping.data.name }}</p>
            <p class="mb-0">Direccion: {{ shipping.data.address }}</p>
            <p class="mb-0">Estado: {{ shipping.data.zone }}</p>
            <p class="mb-0">País: {{ shipping.data.country }}</p>
            <p class="mb-0">Teléfono: {{ shipping.data.phone }}</p>
            <p class="mb-0">Email: {{ shipping.data.email }}</p>
            <p v-if="shipping.data.comentaries !== ''" class="mb-0">Comentarios</p>
            <p>{{ shipping.data.comentaries }}</p>
          </div>

        </div>

        <div class="col-sm-4 text-muted">
          <h5 class="text-muted">Datos de Facturación</h5>
          
          <div v-if="!invoice.required"><p>Factura no requerida</p></div>

          <div v-if="invoice.required" class="">
            <p class="mb-0">Razón Social: {{ invoice.company }}</p>
            <p class="mb-0">RFC: {{ invoice.rfc }}</p>
            <p class="mb-0">Uso de CFDI: {{ invoice.cfdi }}</p>
          </div>

        </div>

        <div class="col-sm-4 text-muted">
          <h5 class="text-muted">Datos de Pago</h5>
          <p class="mb-0"><strong>{{ payment.method }}</strong></p>

          <div v-if="payment.key == 1">
            <p>Estatus: <span :class="status_color">{{ status_text }}</span></p>
          </div>

        </div>

      </div>

      <div class="row px-3"><hr class="col-12"></div>

      <div class="row text-muted pt-2 h6 px-3">
        <div class="col-sm-6 d-none d-md-block">Producto</div>
        <div class="col-sm-2 d-none d-md-block text-right">Cantidad</div>
        <div class="col-sm-2 d-none d-md-block text-right">Subtotal</div>
        <div class="col-sm-2 d-none d-md-block text-right">Total</div>
      </div>

      <div v-for="(item, key) in order.list" :key="key"
        class="row text-muted my-2 px-3 pb-2">
        <div class="col-sm-6">({{ item.model }}) {{ item.description }}</div>
        <div class="col-sm-2 text-right">
          <div class="row d-flex justify-content-between">
            <span class="col-9 d-sm-block d-md-none">Cantidad: </span>
            <span class="col-md-12 col-3">{{ item.quantity }}</span>
          </div>
        </div>
        <div class="col-sm-2 text-right">
          <div class="row d-flex justify-content-between">
            <span class="col-9 d-sm-block d-md-none">Subtotal: </span>
            <span class="col-md-12 col-3">$ {{ item.subtotal }}</span>
          </div>
        </div>
        <div class="col-sm-2 text-right">
          <div class="row d-flex justify-content-between">
            <span class="col-9 d-sm-block d-md-none">Total: </span>
            <span class="col-sm-12 col-3">$ {{ item.total }}</span>
          </div>
        </div>
      </div>

      <hr class="mb-0">

      <div class="row text-muted pt-5">
        <div class="col-6 col-sm-6 pt-2"></div>
        <div class="col-3 col-sm-4 pt-2 text-right"><strong class="text-primary">Subtotal</strong></div>
        <div class="col-3 col-sm-2 pt-2 text-right">$ {{ subtotal }}</div>
      </div>

      <div class="row text-muted pt-2">
        <div class="col-1 col-sm-6"></div>
        <div class="col-8 col-sm-4 text-right"><strong class="text-primary">{{ shipping.method }}</strong></div>
        <div class="col-3 col-sm-2 text-right">$ {{ shipping.total }}</div>
      </div>

      <div class="row text-muted pt-2">
        <div class="col-6 col-sm-6 pb-2"></div>
        <div class="col-3 col-sm-4 pb-2 text-right"><strong class="text-primary">Total</strong></div>
        <div class="col-3 col-sm-2 pb-2 text-right"><strong>$ {{ total }}</strong></div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'Resume',
  data() {
    return {
      date: '',
      show_document: '',
      show_printview: '',
      states: '',
      countries: '',

      status: '',
      status_text: '',

      customer: '',
      order: '',
      shipping: '',
      invoice: '',
      payment: '',

      subtotal: '',
      total: ''
    }
  },
  components: {
    AppStep: () => import('@/components/AppStep.vue')
  },
  methods: {
    print() {
      this.show_document = true
      this.show_printview = true

      this.$nextTick(() => {
          window.print()
      });
    }
  },
  created() {
    this.date = new Date()

    this.show_document = false
    this.show_printview = false
    
    this.status = ''
    this.show_status = '3'

    this.customer = this.$store.getters.get_customer
    this.order = this.$store.getters.get_order
    this.order_final = this.$store.getters.get_order_final
    this.shipping = this.$store.getters.get_shipping
    this.invoice = this.$store.getters.get_invoice
    this.payment = this.$store.getters.get_payment

    this.subtotal = this.order.data.total
    this.total = this.subtotal + this.shipping.total

    if(this.payment.key == 1) {
      console.log(this.order_final)
      switch (this.order_final.order.order_status_id) {
        case '1':
          this.show_status = 1
          this.status_color = "text-success"
          this.status_text = "Completado"
          break;
        case '2':
          this.show_status = 1
          this.status_color = "text-success"
          this.status_text = "Completado"
          break;
        case '10': 
          this.show_status = 2
          this.status_color = "text-danger"
          this.status_text = "Fallido"
          break;
      }
      this.status = this.payment.status
    } 
    if(this.payment.key == 2 || this.payment.key == 3) { this.status = 3 }
  },
}
</script>